<template>
  <div class="app-card">
    <a-row :gutter="16">
      <a-col :span="6">
        <a-divider>保险公司信息</a-divider>
        <a-form-model ref="ruleForm" :rules="rules" :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
          <a-form-model-item label="保险公司归属" prop="baseInsurance">
            <a-select style="width: 150px;" v-model="form.baseInsurance">
              <a-select-option v-for="n in insuranceBaseList" :key="n" :value="n">
                {{n}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="保险公司名称" prop="insuranceName">
            <a-input v-model="form.insuranceName" />
          </a-form-model-item>
          <a-form-model-item label="出单工号" prop="insuranceAccount">
            <a-input v-model="form.insuranceAccount" />
          </a-form-model-item>
          <a-form-model-item label="生效时间" prop="startAt">
            <a-date-picker
              v-model="form.startAt"
              :disabled-date="disabledStartDate"
              show-time
              format="YYYY-MM-DD HH:mm:ss"
              placeholder="Start"
            />
          </a-form-model-item>
          <a-form-model-item label="失效效时间" prop="endAt">
            <a-date-picker
              v-model="form.endAt"
              :disabled-date="disabledEndDate"
              show-time
              format="YYYY-MM-DD HH:mm:ss"
              placeholder="End"
            />
          </a-form-model-item>
          <a-form-model-item label="备注" prop="remarks">
            <a-textarea v-model="form.remarks" :auto-size="{ minRows: 3, maxRows: 5 }" />
          </a-form-model-item>
          <a-form-model-item :wrapper-col="{ span: 14, offset: 8 }">
            <a-button type="primary" @click="CreateHandle" v-if="formMode === 'create'">
              {{formMode === 'edit' ? '编辑政策' : '保存政策'}}
              保存政策
            </a-button>
            <a-button type="primary" @click="EditHandle" v-else>
              编辑政策
            </a-button>
          </a-form-model-item>
        </a-form-model>
      </a-col>
      <a-col :span="18">
        <a-divider>政策条目配置</a-divider>
        <a-collapse>
          <a-collapse-panel :header="it.rateString ? `${it.rateString} | 优先级 ${it.order}` : '请填写政策文本'" v-for="(it, mainIndex) in form.rateList" :key="it.key">
            <div slot="extra">
              <a-popconfirm
                title="是否确定删除该条目?"
                ok-text="是"
                cancel-text="否"
                @confirm="removeRateClick(mainIndex)"
              >
                <a-icon type="minus-circle-o" />
              </a-popconfirm>
              
            </div>
            <a-row :gutter="16">
              <a-col :span="15" v-if="it.matchType === 'LogicMaps'">
                <a-textarea
                  v-model="it.rateString"
                  placeholder="请输入政策文本"
                  :auto-size="{ minRows: 3, maxRows: 6 }"
                />
                <div style="padding: 5px 0;"><a-button type="primary" @click="GetLogicMap(it.rateString)">验证政策文本解析</a-button></div>
              </a-col>
              <a-col :span="it.matchType === 'LogicMaps' ? 9 : 16">
                <div style="padding-bottom:5px;">
                  <span class="rate-form-lable" style="padding-left: 14px;">匹配优先级:</span><a-input-number :min="0" :max="100" v-model="it.order" style="width: 60%" />
                </div>
                <div style="padding-bottom:5px;">
                  <span class="rate-form-lable" style="padding-left: 14px;">政策配类型:</span>
                  <a-select v-model="it.matchType" placeholder="政策配类型" style="width: 60%">
                    <a-select-option value="LogicMaps">
                      特定投保内容
                    </a-select-option>
                    <a-select-option value="Any">
                      任意
                    </a-select-option>
                  </a-select>
                </div>
                <div style="padding-bottom:5px;">
                  <span class="rate-form-lable">保单匹配类型:</span>
                  <a-select v-model="it.type" placeholder="保单匹配类型" style="width: 60%">
                    <a-select-option value="TCI">
                      交强险
                    </a-select-option>
                    <a-select-option value="VCI">
                      商业险
                    </a-select-option>
                    <a-select-option value="And">
                      套单
                    </a-select-option>
                    <a-select-option value="Any">
                      不限
                    </a-select-option>
                  </a-select>
                </div>
                
                <a-divider>保险公司政策</a-divider>
                <a-row :gutter="[16, 8]">
                  <a-col :span="12">
                    <a-input v-model="it.TCIRate" addon-before="交强政策" />
                  </a-col>
                  <a-col :span="12">
                    <a-input v-model="it.VCIRate" addon-before="商业政策" />
                  </a-col>
                  <a-col :span="12">
                    <a-input v-model="it.CarCasualtyRate" addon-before="驾意险政策" />
                  </a-col>
                </a-row>
              </a-col>
            </a-row>
            <a-divider>政策投放</a-divider>
            <div>
              <a-form-model-item
              v-for="(rate, index) in it.rateSet"
              :key="rate.key"
              v-bind="formItemLayout"
              :rules="{
                required: true,
                message: 'domain can not be null',
                trigger: 'blur',
              }"
            >
              <a-card size="small">
                <div slot="title">
                  <a-input addon-before="标记" style="width: 20%" v-model="rate.mark" />
                </div>
                <div>
                  <a-input-group compact>
                    <span class="ant-input-group-addon" style="width: 20%;height: 32px;line-height: 2;">适用机构</span>
                    <a-tree-select
                      show-search
                      style="width: 80%;"
                      v-model="rate.orgs"
                      :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                      placeholder="请选择"
                      allow-clear
                      multiple
                      :tree-data="orgTree"
                      @change="onChangeRateOrgs(mainIndex, index, $event)"
                    >
                    </a-tree-select>
                  </a-input-group>
                </div>
                <div style="padding-top:10px;">
                  <a-input-group compact>
                    <span class="ant-input-group-addon" style="width: 10%;height: 32px;line-height: 2;">交强政策</span>
                    <a-input-number style="width: 22%;" :min="0" :max="100" v-model="rate.TCIRate" />
                    <span class="ant-input-group-addon" style="width: 10%;height: 32px;line-height: 2;">商业政策</span>
                    <a-input-number style="width: 22%;" :min="0" :max="100" v-model="rate.VCIRate" />
                    <span class="ant-input-group-addon" style="width: 12%;height: 32px;line-height: 2;">驾意险政策</span>
                    <a-input-number style="width: auto;" :min="0" :max="100" v-model="rate.CarCasualtyRate" />
                  </a-input-group>
                </div>
                 <a-icon
                  slot="extra"
                  v-if="it.rateSet.length > 1"
                  class="dynamic-delete-button"
                  type="minus-circle-o"
                  :disabled="it.rateSet.length === 1"
                  @click="removeOut(index,mainIndex)"
                  />
              </a-card>
            </a-form-model-item>
            <a-form-model-item v-bind="formItemLayout">
              <a-button type="dashed" style="width: 60%" @click="addOut(mainIndex)">
                <a-icon type="plus" /> 增加投放
              </a-button>
            </a-form-model-item>
            </div>
          </a-collapse-panel>
        </a-collapse>
        <div style="text-align:center;padding-top:10px;">
          <a-button type="dashed" style="width: 30%" @click="addRate">
            <a-icon type="plus" /> 添加条目
          </a-button>
        </div>
      </a-col>
    </a-row>
    <a-drawer
      title="政策文本逻辑图"
      height="100vh"
      placement="bottom"
      :visible="showLogicMap"
      @close="showLogicMap = false"
    >
      <div :style="{ height: `${jsplumbHeight}px` }" class="panel-body points_body points demo flow_chart" id="points"></div>
    </a-drawer>

    <a-modal
      :width="200"
      :visible="modalLoading"
      :closable="false"
      :maskClosable="false"
      :footer="null"
    >
      <a-row>
        <a-col style="text-align: center;">
          <a-icon type="loading" :style="{ fontSize: '36px', color: '#1890ff' }" />
        </a-col>
        <a-col style="text-align: center;color:#1890ff;padding-top:10px;">
          {{modalLoadingMessage}}
        </a-col>
      </a-row>
    </a-modal>
  </div>
</template>

<script>
import licia from 'licia'
import moment from 'moment'
import $ from 'jquery'
import 'jsplumb'
import '@/assets/jsplumb.css'
import { Organization, Rate, Utils } from '@/api'

export default {
  async mounted() {
    if (this.$route.params && this.$route.params.uuid) {
      this.formMode = 'edit'
      this.getDetail(this.$route.params.uuid)
    }
    /* eslint-disable no-undef */
    $(document).ready(() => {
      jsPlumb.ready(() => {
        console.log('jsPlumb ready')
      })
    })
    this.GetInsuranceBaseList()
    const apiRes = await this.$axios.get(`${Organization}/GetTree`)
        .catch(e => {
          return {
            status: false,
            message: e.message.indexOf('401') > -1 ? '登录超时' : e.message
          }
        })
      if (!apiRes.status) {
        this.$message.error(apiRes.message)
        return
      }
      this.$set(this, 'orgTree', apiRes.data)
  },
  data() {
    return {
      formMode: 'create',
      modalLoading: false,
      modalLoadingMessage: '正在处理数据...',
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
      formItemLayout: {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 4 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 20 },
        },
      },
      formItemLayoutWithOutLabel: {
        wrapperCol: {
          xs: { span: 24, offset: 0 },
          sm: { span: 20, offset: 4 },
        },
      },
      form: {
        baseInsurance: '',
        insuranceName: '',
        insuranceAccount: '',
        startAt: null,
        endAt: null,
        remarks: '',
        rateList: []
      },
      rules: {
        baseInsurance: [{ required: true, message: '请选择', trigger: 'change' }],
        insuranceName: [{ required: true, message: '请填写', trigger: 'blur' }],
        insuranceAccount: [{ required: true, message: '请填写', trigger: 'blur' }],
        startAt: [{ required: true, message: '请选择', trigger: 'change' }]
      },
      insuranceBaseList: [],
      orgTree: [],
      showLogicMap: false,
      jsplumbHeight: 42,
      strokeWidth: 3,
      mapData: {},
      mapDataIds: []
    }
  },
  methods: {
    async GetInsuranceBaseList() {
      const apiRes = await this.$axios.get(`${Utils}/InsuranceBaseList`)
        .catch(e => {
          return {
            status: false,
            message: e.message.indexOf('401') > -1 ? '登录超时' : e.message
          }
        })
      if (!apiRes.status) {
        this.$message.error(apiRes.message);
        return;
      }
      this.insuranceBaseList = apiRes.data
    },
    disabledStartDate(startValue) {
      const endValue = this.form.endAt;
      if (!startValue || !endValue) {
        return false;
      }
      return startValue.valueOf() > endValue.valueOf();
    },
    disabledEndDate(endValue) {
      const startValue = this.form.startAt;
      if (!endValue || !startValue) {
        return false;
      }
      return startValue.valueOf() >= endValue.valueOf();
    },
    addRate() {
      this.form.rateList.push({
        key: Date.now(),
        rateString: '',
        matchType: 'LogicMaps',
        type: '',
        TCIRate: '',
        TCISubRate: '',
        VCIRate: '',
        VCISubRate: '',
        order: 0,
        rateSet: [],
      })
    },
    removeRateClick(mainIndex) {
      this.form.rateList.splice(mainIndex, 1);
    },
    removeOut(index, mainIndex) {
      this.form.rateList[mainIndex].rateSet.splice(index, 1);
    },
    addOut(i) {
      this.form.rateList[i].rateSet.push({
        value: 0,
        mark: '',
        key: Date.now(),
        orgs: [],
      });
    },
    onChangeRateOrgs(mainIndex, index, value) {
      this.form.rateList[mainIndex].rateSet[index].orgs = value
    },
    async getDetail(uuid) {
      const apiRes = await this.$axios.get(`${Rate}/Detail?uuid=${uuid}`)
        .catch(e => {
          return {
            status: false,
            message: e.message.indexOf('401') > -1 ? '登录超时' : e.message
          }
        })
      if (!apiRes.status) {
        this.$message.error(apiRes.message);
        this.sending = false;
        return null;
      }
      this.beforeData = apiRes.data
      const form = {
        baseInsurance: apiRes.data.baseInsurance,
        insuranceName: apiRes.data.insuranceName,
        insuranceAccount: apiRes.data.insuranceAccount,
        startAt: moment(apiRes.data.startAt).format('YYYY-MM-DD HH:mm:ss'),
        endAt: apiRes.data.endAt ? moment(apiRes.data.endAt).format('YYYY-MM-DD HH:mm:ss') : null,
        remarks: apiRes.data.remarks,
        rateList: apiRes.data.rateList
      }
      this.$set(this, 'form', JSON.parse(JSON.stringify(form)))
    },
    // 编辑
    EditHandle() {
      const _this = this;
      this.$confirm({
        title: '是否确认编辑当前政策',
        content: '编辑当前政策将生成新政策，并使之前政策失效！',
        onOk() {
          _this.CreateHandle()
        },
      })
    },
    // 保存
    async CreateHandle() {
      this.$refs.ruleForm.validate(async valid => {
        if (!valid) {
          this.$message.error('请检查填写内容')
          return false
        }
        const postData = JSON.parse(JSON.stringify(this.form))
        postData.startAt = moment(this.form.startAt).format('YYYY-MM-DD HH:mm:ss')
        postData.end = postData.end ? moment(this.form.endAt).format('YYYY-MM-DD HH:mm:ss') : null;
        let url = ''
        if (this.formMode === 'create') {
          url = `${Rate}/CreatedByCar`
        } else {
          url = `${Rate}/EditByCar?uuid=${this.beforeData.uuid}`
        }
        this.modalLoadingMessage = '正在处理数据'
        this.modalLoading = true
        const res = await this.$axios.post(url, postData)
          .catch(e => {
            return {
              status: false,
              message: e.message.indexOf('401') > -1 ? '登录超时' : e.message
            }
          })
        if (!res.status) {
          this.modalLoading = false
          this.$message.error(res.message)
          return
        }
        this.$message.success("建立政策完成！")
        this.$router.replace({ name: 'RateCarList' }).catch(err => err)
      })
    },
    // 政策逻辑图
    async GetLogicMap (text) {
      const apiRes = await this.$axios.post(`${Rate}/GetLogicMap`, {
        rateString: text,
      })
        .catch(e => {
          return {
            status: false,
            message: e.message.indexOf('401') > -1 ? '登录超时' : e.message
          }
        })
      if (!apiRes.status) {
        this.$message.error(apiRes.message)
        return
      }

      this.showLogicMap = true
      this.cleanFlow()
      this.$nextTick(() => {
        this.createFlow(apiRes.data)
      })
    },
    createFlow (flowData) {
      const color = '#acd'
      const strokeWidth = this.strokeWidth
      this.instance = jsPlumb.getInstance({
        Connector: ['Bezier', { curviness: 50 }],
        Endpoint: ['Dot', { radius: 5 }],
        DragOptions: { cursor: 'pointer', zIndex: 5000 },
        PaintStyle: { strokeWidth, stroke: '#445566' },
        EndpointStyle: { radius: 9, fill: color, stroke: 'red' },
        ConnectionOverlays: [
          ['Arrow', {
            location: 1,
            id: 'arrow',
            length: 4,
            foldback: 0.8,
            paintStyle: {
              lineWidth: 5,
              stroke: 'lightgray',
              fill: 'lightgray'
            }
          }]
        ],
        Container: 'points'
      })
      // suspend drawing and initialise.
      const pointLocation = {
        top: 0,
        left: 0
      }
      const addedIds = []
      const lines = []
      const pointNameCache = {}
      this.instance.batch(() => {
        for (const itLevel of flowData) {
          for (const it of itLevel) {
            // name 转换 start
            if (it.Point.Name === 'newCar') {
              it.Point.Name = '新车'
            } else if (it.Point.Name === 'hasPI_A') {
              it.Point.Name = '车损'
            } else if (it.Point.Name === '_useTypecarType1_') {
              it.Point.Name = '非运营客车'
            } else if (it.Point.Name === '_useTypecarType2_') {
              it.Point.Name = '非运营货车'
            } else if (it.Point.Name === '_useTypecarType3_') {
              it.Point.Name = '运营客车'
            } else if (it.Point.Name === '_useTypecarType4_') {
              it.Point.Name = '运营货车'
            }

            lines.push([ ...it.Line, it.Logic ])
            if (addedIds.includes(it.Id)) {
              continue
            }

            // name 转换 end
            const itemClass = `point_${it.Point.Type}`

            // 节点名称处理 start
            pointNameCache[it.Id] = it.Point.Name
            if (it.Point.Type === 'combo' || it.Point.Type === 'out') {
              let pointNameStr = '('
              if (it.Point.And.length > 0) {
                for (const andKey of it.Point.And) {
                  // console.log(pointNameCache, andKey, 'ssss')
                  pointNameStr += `${pointNameCache[andKey]}And`
                }
                pointNameStr = pointNameStr.substring(0, pointNameStr.length - 3) + ')'
              }
              if (it.Point.Or.length > 0) {
                if (pointNameStr.length > 1) {
                  pointNameStr += "And("
                }
                for (const orKey of it.Point.Or) {
                  // console.log(pointNameCache, orKey, 'rrrr')
                  pointNameStr += `${pointNameCache[orKey]}Or`
                }
                pointNameStr = pointNameStr.substring(0, pointNameStr.length - 2) + ')'
              }
              console.log(pointNameStr, 'pointNameStr')
              pointNameCache[it.Id] = pointNameStr
              if (pointNameStr === '(') {
                console.log(pointNameCache, it)
              }
            }
            // 节点名称处理 end

            const pointNameRawLen = pointNameCache[it.Id].length < 8 ? 8 : pointNameCache[it.Id].length
            $('.points').append(`<div style="width:${pointNameRawLen}em; left: ${pointLocation.left}px; top: ${pointLocation.top}px;" id="${it.Id}" class="oa-text-hidden point ${itemClass} chart_act_${it.Point.Type}">${pointNameCache[it.Id]}</div>`)
            this.instance.draggable(`${it.Id}`)
            pointLocation.left += $(`#${it.Id}`).width() + 30

            if (it.Point.Type !== 'out') {
              this.instance.addEndpoint(it.Id, {
                uuid: `${it.Id}-bottom`,
                anchor: 'Bottom',
                maxConnections: -1
              }, {
                isSource: true,
                isTarget: true,
                dragAllowedWhenFull: true
              })
            }
            if (it.Point.Type !== 'in') {
              this.instance.addEndpoint(it.Id, {
                uuid: `${it.Id}-top`,
                anchor: 'Top',
                maxConnections: -1
                // connectorStyle: { stroke: 'gray',  strokeWidth },
              }, {
                isSource: true,
                isTarget: true,
                dragAllowedWhenFull: true
              })
            }

          }
          
          pointLocation.left = 0
          pointLocation.top += 120
          this.jsplumbHeight += 120
        }
        // init transition
        for (const i of lines) {
          const uuid = [`${i[0]}-bottom`, `${i[1]}-top`]
          let label = ''
          if (!licia.isArr(i[2].opr)) {
            switch (i[2].opr) {
              case 'exist':
                label = '存在'
                break
              case 'non-exist':
                label = '不存在'
                break
              default:
                label = i[2].opr + i[2].value.toString()
                break
            }
          } else {
            for (let li = 0; li <= i[2].opr.length - 1; li++) {
              label += i[2].opr[li] + i[2].value[li].toString()
            }
          }
          this.instance.connect({
            uuids: uuid,
            overlays: [
              ['Arrow', { location: 0.7 }, { foldback: 0.7, width: 12 }],
              ['Label', { label, id: 'label' }]
            ],
            paintStyle: { strokeWidth, stroke: '#1E90FF' }
          })
        }
      })
    },
    cleanFlow () {
      if (this.instance) {
        for (const it of this.mapDataIds) {
          this.instance.remove(it)
        }
      }
      $('.points').empty()
    }
  }
}
</script>

<style>
  .rate-form-lable {
    font-weight: 500;
    font-size: 14px;
    padding-right: 5px;
  }
  .dynamic-delete-button {
  cursor: pointer;
  position: relative;
  padding-left: 5px;
  top: 4px;
  font-size: 18px;
  color: #999;
  transition: all 0.3s;
}
.dynamic-delete-button:hover {
  color: #777;
}
.dynamic-delete-button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}
</style>